import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { getRuns, putPricingRun } from "../../store/run/runFunction";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import CheckboxControl from "../../components/CheckboxControl/CheckboxControl";
import { RunStatus } from "../../models/run";

type ApproveRunModalProps = {
  open: boolean;
  handleClose: () => void;
  runId: number;
};

const schema = z.object({
  internalOnly: z.boolean(),
});

export default function ApproveRunModal({
  open,
  handleClose,
  runId,
}: ApproveRunModalProps) {
  const loading = useAppSelector((state) => state.runs.loading);
  const dispatch = useAppDispatch();

  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit, reset } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      internalOnly: false,
    },
  });
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Approve Pricing Run"}</DialogTitle>
      <form
        onSubmit={handleSubmit(async (data) => {
          await dispatch(
            putPricingRun({
              runId,
              status: RunStatus.COMPLETED,
              internalOnly: data.internalOnly,
            }),
          );
          await dispatch(getRuns(null));
          reset();
          handleClose();
        })}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking 'Approve', the pricing run will be marked as completed
            and will be distributed. Do you want to proceed?
          </DialogContentText>
          <Grid container alignItems="center" sx={{ pt: 3 }}>
            <Grid item>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Distribute internally only
              </Typography>
            </Grid>
            <Grid item>
              <CheckboxControl name="internalOnly" control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              reset();
              handleClose();
            }}
          >
            Back
          </Button>
          <ProgressButton
            inProgress={loading}
            text="Approve"
            Icon={NavigateNextIcon}
            color="#ffffff"
            backgroundColor="#2040cd"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}
