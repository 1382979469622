import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import { useAppSelector } from "../../store/store";
import {
  daysInYear,
  getEndFinancialYear,
  getPreviousBusinessDay,
  getStartFinancialYear,
} from "../../utils/DateUtils";
import SelectFundStep from "./SelectFundStep";
import SelectModelTimingStep from "./SelectModelTimingStep";
import SelectUnitsAllocationsStep from "./SelectUnitsAllocationsStep";
import SelectMonthlyDistributionsStep from "./SelectMonthlyDistributionsStep";
import { MonthlyDistribution, RunMetadata } from "../../models/run";
import SelectFinancialStatementsStep from "./SelectFinancialStatementsStep";

type ModalProps = {
  open: boolean;
  handleClose: () => void;
};

export type NewRun = {
  fundId: number;
  modelTiming: {
    runDate: Date | string;
    startDate: Date | string;
    endDate: Date | string;
    daysInYear: number;
  };
  unitsAllocations: {
    unitsOnIssue: number;
    applicationUnits: number;
    applicationFlows: number;
    redemptionUnits: number;
    redemptionFlows: number;
    capitalFlows: number;
    distributionsPayable: number;
  };
  monthlyDistributions: Array<MonthlyDistribution>;
  adjustmentsToGrossProfit: number;
  cashRate: number;
};

// RunCreationModal component
export default function RunCreationModal({ open, handleClose }: ModalProps) {
  const funds = useAppSelector((state) => state.funds).funds;

  const steps = [
    "Fund",
    "Model Timing",
    "Statements",
    "Units",
    "Monthly Distributions",
  ];

  const defaultModelTiming = {
    runDate: getPreviousBusinessDay(),
    startDate: getStartFinancialYear(),
    endDate: getEndFinancialYear(),
    daysInYear: daysInYear(),
  };

  const initialRunValues = {
    fundId: 0,
    modelTiming: defaultModelTiming,
    unitsAllocations: {
      unitsOnIssue: 0,
      applicationUnits: 0,
      applicationFlows: 0,
      redemptionUnits: 0,
      redemptionFlows: 0,
      capitalFlows: 0,
      distributionsPayable: 0,
    },
    monthlyDistributions: [],
    adjustmentsToGrossProfit: 0,
    cashRate: 4.35,
  };

  const [newRun, setNewRun] = useState(initialRunValues);
  const [lastModel, setLastModel] = useState<RunMetadata | null>(null);
  const [netsuiteCodes, setNetsuiteCodes] = useState<string>("");

  // Following values for Stepper
  // -----------------------------------------------------------------

  const [activeStep, setActiveStep] = React.useState(0);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  // This also handles the data movement between each individual form during the stepper flow
  const handleNext = (data: { [key: string]: any }) => {
    // Update the run object with form data
    setNewRun((prev) => ({
      ...prev,
      ...data,
    }));
    if (isLastStep()) {
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setNewRun(initialRunValues);
  };

  // -----------------------------------------------------------------

  return (
    <Dialog
      onClose={() => handleClose()}
      open={open}
      sx={{ "& .MuiDialog-paper": { width: 700, maxWidth: 700 } }}
    >
      <DialogTitle>New Pricing Run</DialogTitle>
      <DialogContent sx={{ height: "100%" }}>
        <Grid container rowSpacing={4} alignItems="center">
          <Grid item sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={index < activeStep}>
                  <StepButton color="inherit" disabled={true}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Grid>

          <Grid item container rowSpacing={4} alignItems="center">
            <React.Fragment>
              {/* Conditional render for form items*/}
              {steps[activeStep] === "Fund" && (
                <SelectFundStep
                  funds={funds}
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleReset={handleReset}
                  handleClose={handleClose}
                  newRun={newRun}
                  setLastModel={setLastModel}
                  setNetsuiteCodes={setNetsuiteCodes}
                />
              )}
              {steps[activeStep] === "Model Timing" && (
                <SelectModelTimingStep
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleReset={handleReset}
                  handleClose={handleClose}
                  newRun={newRun}
                  previousModelTiming={
                    lastModel?.unitPriceDataModel.modelTiming ||
                    defaultModelTiming
                  }
                />
              )}
              {steps[activeStep] === "Statements" && (
                <SelectFinancialStatementsStep
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleReset={handleReset}
                  handleClose={handleClose}
                  newRun={newRun}
                  netsuiteCodes={netsuiteCodes}
                />
              )}
              {steps[activeStep] === "Units" && (
                <SelectUnitsAllocationsStep
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleReset={handleReset}
                  handleClose={handleClose}
                  newRun={newRun}
                  priorDayUnits={
                    lastModel?.unitPriceDataModel.unitsAllocations
                      .unitsOnIssue || 0
                  }
                />
              )}
              {steps[activeStep] === "Monthly Distributions" && (
                <SelectMonthlyDistributionsStep
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleReset={handleReset}
                  handleClose={handleClose}
                  newRun={newRun}
                />
              )}
            </React.Fragment>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
