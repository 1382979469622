import React from "react";
import { IconButton, MenuItem, SelectChangeEvent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Page } from "../../layouts/components";
import { useAppSelector } from "../../store/store";
import { useState } from "react";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DistributionEditModal from "../../components/Modals/DistributionEditModal";
import { Distribution } from "../../models/distribution";
import DistributionDeleteModal from "../../components/Modals/DistributionDeleteModal";
import PageTitle from "../../components/PageTitle/PageTitle";
import BorderedContainer from "../../components/BorderedContainer/BorderedContainer";
import ControlBar from "../../components/ControlBar/ControlBar";
import ControlBarSection from "../../components/ControlBarSection/ControlBarSection";
import UnitPriceDataGrid from "../../components/DataGrid/DataGrid";
import SearchControl from "../../components/SearchControl/SearchControl";
import StyledSelect from "../../components/StyledSelect/StyledSelect";
import { StyledFormControl } from "../../components/StyledFormControl/StyledFormControl";
import { StyledButton } from "../../components/StyledButton/StyledButton";
import { UnitPricingActions } from "../../types/UserTypes";

const ActionButtons = ({
  handleOpenEditModal,
  handleOpenDeleteModal,
  distribution,
}: {
  handleOpenEditModal: (distribution: Distribution) => void;
  handleOpenDeleteModal: (distribution: Distribution) => void;
  distribution: Distribution;
}) => {
  const [deleteHover, setDeleteHover] = useState(false);
  const [editHover, setEditHover] = useState(false);

  return (
    <div>
      <IconButton
        size="small"
        disableRipple
        onMouseEnter={() => setDeleteHover(true)}
        onMouseLeave={() => setDeleteHover(false)}
        onClick={() => handleOpenDeleteModal(distribution)}
      >
        <DeleteOutlineIcon
          style={{ color: deleteHover ? "#2040cd" : "#BBBBBB" }}
          fontSize="small"
        />
      </IconButton>
      <IconButton
        size="small"
        disableRipple
        onMouseEnter={() => setEditHover(true)}
        onMouseLeave={() => setEditHover(false)}
        onClick={() => handleOpenEditModal(distribution)}
      >
        <EditIcon
          style={{ color: editHover ? "#2040cd" : "#BBBBBB" }}
          fontSize="small"
        />
      </IconButton>
    </div>
  );
};

// Process Runs
export default function Distributions() {
  const [selectedDistribution, setSelectedDistribution] = React.useState(
    {} as Distribution,
  );
  const [selectedFundCode, setSelectedFundCode] = React.useState("");
  const [selectedClass, setSelectedClass] = React.useState("");
  const [searchText, setSearchText] = useState("");
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  const actions = useAppSelector((state) =>
    state.user.user?.permissions.actions.map((a) => a.permission),
  );

  const handleOpenEditModal = (distribution: Distribution) => {
    if (distribution) setSelectedDistribution(distribution);
    setOpenEditModal(true);
  };
  const handleOpenDeleteModal = (distribution: Distribution) => {
    if (distribution) setSelectedDistribution(distribution);
    setOpenDeleteModal(true);
  };

  const handleCloseEditModal = () => setOpenEditModal(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const funds = useAppSelector((state) => state.funds).funds;
  const distributions = useAppSelector(
    (state) => state.distributions.distributions,
  );

  const handleSelectedFundCodeChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedFundCode(event.target.value as string);
  };

  const handleSelectedClassChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedClass(event.target.value as string);
  };

  let columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Fund",
      flex: 1,
      valueGetter: (params) => params.row.fundCode,
    },
    {
      field: "class",
      headerName: "Class",
      flex: 1,
    },
    {
      field: "salutation",
      headerName: "Salutation",
      flex: 1,
    },
    {
      field: "internal",
      headerName: "Type",
      flex: 1,
      valueGetter: (params) => (params.row.internal ? "Internal" : "External"),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
    },
    {
      field: "actions",
      headerName: "",
      renderCell: (params) => (
        <>
          {actions &&
            actions.some((a) => a === UnitPricingActions.EDIT_ALL) && (
              <ActionButtons
                handleOpenEditModal={handleOpenEditModal}
                handleOpenDeleteModal={handleOpenDeleteModal}
                distribution={params.row}
              />
            )}
        </>
      ),
    },
  ];

  const filteredDistributions = distributions.filter((distribution) => {
    // If a fund is selected, filter by fund
    const fundMatches =
      !selectedFundCode || distribution.fundCode === selectedFundCode;

    // If a search text is entered, filter by search text
    const lowerSearchText = searchText?.toLowerCase();
    const searchTextMatches =
      !searchText ||
      distribution.salutation.toLowerCase().includes(lowerSearchText) ||
      distribution.email.toLowerCase().includes(lowerSearchText) ||
      (distribution.internal && "internal".includes(lowerSearchText)) ||
      (!distribution.internal && "external".includes(lowerSearchText));

    return fundMatches && searchTextMatches;
  });

  return (
    <Page title="Email Distribution">
      <PageTitle title="Email Distribution" />
      <BorderedContainer>
        <ControlBar>
          <ControlBarSection>
            <StyledFormControl
              className="control-bar-section-item"
              fullWidth
              size="small"
            >
              <StyledSelect
                labelId="funds-select-label"
                id="funds-select"
                label="Funds"
                displayEmpty
                value={selectedFundCode} // Set the value prop to selectedFund
                onChange={handleSelectedFundCodeChange} // Set the onChange prop to handleSelectedFundChange
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">All Funds</MenuItem>
                {funds.map((fund) => (
                  <MenuItem key={fund.fundId} value={fund.fund.code}>
                    {fund.fund.code}
                  </MenuItem>
                ))}
              </StyledSelect>
            </StyledFormControl>
            <StyledFormControl
              className="control-bar-section-item"
              fullWidth
              size="small"
            >
              <StyledSelect
                className="select-field"
                labelId="class-select-label"
                id="class-select"
                label="Classes"
                displayEmpty
                value={selectedClass} // Set the value prop to selectedClass
                onChange={handleSelectedClassChange} // Set the onChange prop to handleSelectedClassChange
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">All Classes</MenuItem>
                <MenuItem value={1}>Wholesale</MenuItem>
              </StyledSelect>
            </StyledFormControl>
          </ControlBarSection>
          <ControlBarSection>
            <SearchControl
              searchText={searchText}
              setSearchText={setSearchText}
            />
            {actions &&
              actions.some((a) => a === UnitPricingActions.EDIT_ALL) && (
                <StyledFormControl fullWidth>
                  <StyledButton
                    variant="contained"
                    onClick={() => handleOpenEditModal({} as Distribution)}
                    disabled={false}
                  >
                    <AddIcon fontSize="small" />
                    Add Recipient
                  </StyledButton>
                </StyledFormControl>
              )}
          </ControlBarSection>
        </ControlBar>
        <UnitPriceDataGrid columns={columns} rows={filteredDistributions} />
      </BorderedContainer>
      <DistributionEditModal
        open={openEditModal}
        distribution={selectedDistribution}
        handleClose={handleCloseEditModal}
      />
      <DistributionDeleteModal
        open={openDeleteModal}
        distribution={selectedDistribution}
        handleClose={handleCloseDeleteModal}
      />
    </Page>
  );
}
