import { Fund } from "./fund";
import { Fees, FundInformation, PreviousPeriodInputs } from "./fundDetails";

export interface Run {
  status: RunStatus;
  metadata: RunMetadata;
  modelDate: Date | string;
  fundId: number;
  fund: Fund;
  id: number;
  createdTimestamp: string;
  createdBy: string;
  lastModifiedTimestamp?: string;
  lastModifiedBy?: string;
  approvedBy?: string;
  approvedTimestamp?: string;
}

export enum RunStatus {
  CANCELLED = "Cancelled",
  PENDING = "Pending",
  REVIEWED = "Reviewed",
  COMPLETED = "Completed",
}

export interface RunMetadata {
  unitPriceDataModel: UnitPriceDataModel;
  unitPriceModelOutputs: UnitPriceModelOutputs;
}

export interface UnitPriceDataModel {
  fundInformation: FundInformation;
  modelTiming: ModelTiming;
  fundFinancials: FundFinancials;
  unitsAllocations: UnitsAllocations;
  monthlyDistributions: Array<MonthlyDistribution>;
  fees: Fees;
  previousPeriodInputs: PreviousPeriodInputs;
  rates: Rates;
  historicalData: HistoricalData;
  pricingHolidays: Array<PricingHoliday>;
}

export interface ModelTiming {
  runDate: Date | string;
  startDate: Date | string;
  endDate: Date | string;
  daysInYear: number;
}

export interface FundFinancials {
  grossProfit: number;
  adjustmentsToGrossProfit: number;
  priorDayNavBeforePerfFee: number;
  adjustmentToNavBeforeFee: number;
}

export interface UnitsAllocations {
  unitsOnIssue: number;
  applicationUnits: number;
  redemptionUnits: number;
  capitalFlows: number;
  distributionsPayable: number;
}

export interface MonthlyDistribution {
  date: Date | string;
  amountPerUnit: number;
}

export interface Rates {
  cashRate: number;
  bbsw3mRate: number;
}

export interface HistoricalData {
  historicalModelDate: Date | string;
  historicalModelId: number;
  historicalReturnPerUnitHurdle: number;
  historicalClosingBalance: number;
  historicalReturnAboveHurdle: number;
  historicalAppEqualisationCum: number;
  historicalCumCrystFee: number;
  historicalAllowanceForRitc: number;
  historicalPerformanceFee: number;
}

export interface PricingHoliday {
  date: Date | string;
  businessDay: boolean;
}

export interface UnitPriceModelOutputs {
  accrualDays: number;
  dailyReFee: number;
  hurdleRate: number;
  dailyIeeFee: number;
  dailyImfFee: number;
  dailyAdminFee: number;
  dailyHurdleRate: number;
  navPostPerfFees: number;
  dailyRegistryFee: number;
  navBeforePerfFees: number;
  returnPerUnitFund: number;
  cumCrystallisedFee: number;
  dailyManagementFee: number;
  additionsRecoveries: number;
  returnPerUnitHurdle: number;
  totalPerformanceFee: number;
  dailyCrystallisedFee: number;
  performanceFeePerUnit: number;
  totalEqualisedPerfFee: number;
  unitPricePostPerfFees: number;
  totalUnequalisedPerfFee: number;
  totalUsedToCalcExpenses: number;
  unitPriceBeforePerfFees: number;
  unitPricePeriodStartAdj: number;
  feePctOnReturnAboveHurdle: number;
  crystallisedPerformanceFee: number;
  totalClassSpecificExpenses: number;
  applicationEqualisationDaily: number;
  outperformanceClosingBalance: number;
  sumMonthlyDistributionsPriorDay: number;
  priorDayNavBeforePerfAndCapFlows: number;
  applicationEqualisationCumulative: number;
  dailyMovement: number;
  monthlyDistribution: number;
  unitPricePostPerfFeesExMonthEnd: number;
  dailyOtherFee1: number;
  dailyOtherFee2: number;
}
