export interface User {
  id: string;
  mipadId: number;
  displayName: string;
  givenName: string;
  groups: Array<UserGroup>;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  profile: string;
  surname: string;
  permissions: {
    access: Array<UserPermissions>;
    actions: Array<UserPermissions>;
  };
}

export interface UserGroup {
  id: string;
  displayName: string;
  desc: string;
}

export interface UserPermissions {
  role: string;
  roleDesc: string;
  permission: string;
  permissionDesc: string;
}

export enum UnitPricingActions {
  APPROVE = "up_approve",
  EDIT_ALL = "up_edit_all",
}
