import { Grid, GridProps } from "@mui/material";

interface NewRunNavGridContainerProps extends GridProps {
  children: React.ReactNode;
}

export default function NewRunNavGridContainer({
  children,
  ...props
}: NewRunNavGridContainerProps) {
  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-end"
      justifyContent="flex-end"
      style={{ height: "100%" }}
      paddingTop={4}
      {...props}
    >
      <Grid item xs={12} container justifyContent="flex-end">
        {children}
      </Grid>
    </Grid>
  );
}
