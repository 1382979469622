import { Box, Button, Divider, Grid, Alert } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewRun } from "./RunCreationModal";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextFieldControl from "../../components/TextFieldControl/TextFieldControl";
import { addValues } from "../../utils/mathUtil";
import NewRunNavGridContainer from "./Components/NewRunNavGridContainer/NewRunNavGridContainer";
import NewRunInputGridItemContainer from "./Components/NewRunInputGridItemContainer/NewRunInputGridItemContainer";
import NewRunInputGridItem from "./Components/NewRunInputGridItem/NewRunInputGridItem";
import NewRunFormLabel from "./Components/NewRunFormLabel/NewRunFormLabel";

interface SelectUnitsAllocationsProps {
  activeStep: number;
  handleBack: () => void;
  handleNext: (data: { [key: string]: any }) => void;
  handleReset: () => void;
  handleClose: () => void;
  newRun: NewRun;
  priorDayUnits: number;
}

// Define Zod schema for form validation
const schema = z.object({
  unitsAllocations: z.object({
    unitsOnIssue: z.coerce
      .number()
      .positive()
      .refine((val) => val > 0, "Must be greater than 0"),
    applicationUnits: z.coerce.number(),
    applicationFlows: z.coerce.number(),
    redemptionUnits: z.coerce.number(),
    redemptionFlows: z.coerce.number(),
    capitalFlows: z.coerce.number(),
    distributionsPayable: z.coerce.number(),
  }),
  adjustmentsToGrossProfit: z.coerce.number(),
});

export default function SelectUnitsAllocationsStep({
  activeStep,
  handleBack,
  handleNext,
  handleReset,
  handleClose,
  newRun,
  priorDayUnits,
}: SelectUnitsAllocationsProps) {
  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit, setValue } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      unitsAllocations: {
        unitsOnIssue:
          priorDayUnits +
          newRun.unitsAllocations.applicationUnits -
          newRun.unitsAllocations.redemptionUnits,
        applicationUnits: newRun.unitsAllocations.applicationUnits,
        applicationFlows: newRun.unitsAllocations.applicationFlows,
        redemptionUnits: newRun.unitsAllocations.redemptionUnits,
        redemptionFlows: newRun.unitsAllocations.redemptionFlows,
        capitalFlows: newRun.unitsAllocations.capitalFlows,
        distributionsPayable: newRun.unitsAllocations.distributionsPayable,
      },
      adjustmentsToGrossProfit: newRun.adjustmentsToGrossProfit,
    },
  });

  const applicationUnits = useWatch({
    control,
    name: "unitsAllocations.applicationUnits",
  });
  const applicationFlows = useWatch({
    control,
    name: "unitsAllocations.applicationFlows",
  });
  const redemptionUnits = useWatch({
    control,
    name: "unitsAllocations.redemptionUnits",
  });
  const redemptionFlows = useWatch({
    control,
    name: "unitsAllocations.redemptionFlows",
  });
  const distributionsPayable = useWatch({
    control,
    name: "unitsAllocations.distributionsPayable",
  });

  const [warningFields, setWarningFields] = useState<string[]>([]);

  useEffect(() => {
    const fields: string[] = [];
    if (applicationUnits < 0)
      fields.push("Application Units (expected positive)");
    if (applicationFlows < 0)
      fields.push("Application Flows (expected positive)");
    if (redemptionUnits < 0)
      fields.push("Redemption Units (expected positive)");
    if (redemptionFlows < 0)
      fields.push("Redemption Flows (expected positive)");
    if (distributionsPayable > 0)
      fields.push("Distributions Payable (expected negative)");
    setWarningFields(fields);
  }, [
    applicationUnits,
    applicationFlows,
    redemptionUnits,
    redemptionFlows,
    distributionsPayable,
  ]);

  useEffect(() => {
    const diff = addValues(applicationUnits, -redemptionUnits);
    setValue("unitsAllocations.unitsOnIssue", addValues(priorDayUnits, diff));
  }, [applicationUnits, redemptionUnits, priorDayUnits, setValue]);

  useEffect(() => {
    const capitalFlows = addValues(applicationFlows, -redemptionFlows);
    setValue("unitsAllocations.capitalFlows", capitalFlows);
  }, [applicationFlows, redemptionFlows, setValue]);

  return (
    <>
      <form
        onSubmit={handleSubmit((data) => {
          handleNext(data);
        })}
      >
        <NewRunInputGridItemContainer>
          {/* ------------------------------------------------------------- */}
          {/* APPLICATION UNITS */}
          <NewRunFormLabel label={"Application Units"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.applicationUnits"
              control={control}
              placeholder="Application units"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* APPLICATION FLOWS */}
          <NewRunFormLabel label={"Application Flows"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.applicationFlows"
              control={control}
              placeholder="Application Flows"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* REDEMPTION UNITS */}
          <NewRunFormLabel label={"Redemption Units"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.redemptionUnits"
              control={control}
              placeholder="Redemption units"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* REDEMPTION FLOWS */}
          <NewRunFormLabel label={"Redemption Flows"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.redemptionFlows"
              control={control}
              placeholder="Redemption Flows"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* DISTRIBUTIONS PAYABLE */}
          <NewRunFormLabel label={"Distributions Payable"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.distributionsPayable"
              control={control}
              placeholder="Distributions payable"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* ADJUSTMENTS TO GROSS PROFIT */}
          <NewRunFormLabel label={"Adjustments to Gross Profit"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="adjustmentsToGrossProfit"
              control={control}
              placeholder="Adjustments to gross profit"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* UNITS ON ISSUE */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <NewRunFormLabel label={"Units On Issue"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.unitsOnIssue"
              control={control}
              placeholder="Units on issue"
              disabled={true}
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* CAPITAL FLOWS */}
          <NewRunFormLabel label={"Capital Flows"} />
          <NewRunInputGridItem>
            <TextFieldControl
              name="unitsAllocations.capitalFlows"
              control={control}
              placeholder="Capital flows"
              disabled={true}
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          <Grid item xs={12}>
            {warningFields.length > 0 && (
              <Alert severity="warning" sx={{ width: "100%" }}>
                Warning: {warningFields.join(", ")}
              </Alert>
            )}
          </Grid>
          <NewRunNavGridContainer>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                handleReset();
                handleClose();
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <ProgressButton
              inProgress={false}
              text="Next"
              Icon={NavigateNextIcon}
              color="#ffffff"
              backgroundColor="#2040cd"
            />
          </NewRunNavGridContainer>
        </NewRunInputGridItemContainer>
      </form>
    </>
  );
}
